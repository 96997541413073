import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'

import './styles.scss'

const InnerSection = ({ variant, className, children }) => {
    const classes = [
        'c-inner-section',
        ...(variant
            ? [`c-inner-section--${variant}`]
            : ['c-inner-section--light']),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return <div className={concatenatedClasses}>{children}</div>
}

InnerSection.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark', 'alt', 'gradient']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

InnerSection.defaultProps = {
    variant: 'light',
    className: '',
    children: '<p>Section content goes here</p>'
}

export default InnerSection
